import { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import Twitter from '../assets/twitter';
import Pattern from '../assets/pattern.svg';
import Edge from '../assets/edge.svg';

export const Profile = styled.div`
  width: 240px;
  height: 240px;
  border-radius: 240px;
  border: 8px solid #172018;
  overflow: hidden;
`

const Inner = styled.div`
  text-align: center;
  align-items: center;
  flex-direction: column;
  width: 280px;
  display: flex;
  gap: 24px;
  @media (min-width: 600px) {
    width: 340px;
  }
`

const Section = styled.section`
  z-index: 0;
  background: #EF718B;
  background-image: url(${Pattern});
  background-repeat: repeat;
  background-position: 50% 50%;
  background-size: 200px;
  background-attachment: fixed;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  // Additional
  background-color: #EBB803;
  alignItems: center;
  @media (min-width: 1024px) {
    height: 100vh;
    min-height: 800px;
  }
`

const Title = styled.span`
  margin: 0;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 1px;
`

const Heading = styled.h3`
  margin: 0;
  font-size: 24px;
  font-family: bungee, sans-serif;
  font-weight: 400;
  font-style: normal;
`

const Body = styled.p`
  margin: 0;
  margin-top: 16px; 
  font-size: 21px; 
  font-weight: 800;
`

const Link = styled.a`
  display: flex;
  gap: 12px;
  text-decoration: none;
  color: var(--primary);
  font-weight: 800;
  margin-top: 24px;
  align-items: center;
  padding: 21px 24px;
  border-radius: 100px;
  &:hover {
    background: var(--primary);
    color: white;
    svg {
      fill: white;
    }
  }
`

const SectionEdgePattern = styled.div`
  -webkit-mask-image: url(${Edge});
  mask-image: url(${Edge});
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  display: flex;
  width: 100%;
  height: 56px;
  background: orange;
  position: absolute;
  top: -55px;
  background-color: #EBB803;
  background-image: url(${Pattern});
  background-repeat: repeat, no-repeat;
  background-position: 50% 50%;
  background-size: 200px, cover;
  background-attachment: fixed;
  }
`

const Wrapper = styled.div(({ adrenaline, onLoad }: {adrenaline?: boolean; onLoad?: boolean; }) => ({
  display: 'grid', 
  gridTemplateColumns: 'auto', 
  gap: 48, 
  alignItems: 'start',
  padding: '96px 0 96px 0',
  '@media (min-width: 900px)': {
    display: 'grid', 
    gridTemplateColumns: 'auto auto', 
    gap: 96, 
    alignItems: 'start',
    padding: '0px 0 0px 0',
  },
  '@media (min-width: 1024px)': {
    display: 'grid', 
    gridTemplateColumns: 'auto auto', 
    gap: 96,
    alignItems: 'start',
  },
  '@media (min-width: 1300px)': {
    gap: 200,
  },
}));


export const TeamTile: FC<{ img: string; title: string; name: string; description: string; link: string; linkTitle: string; }> = ({ img, link, linkTitle, title, name, description }) => {
  return (
    <Inner>
      <Profile>
      <img width="120" src={img} alt={title} style={{width: '100%', height: 'auto'}} />
      </Profile>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Title>{title}</Title>
        <Heading>{name}</Heading>
        <Body>{description}</Body>
        <Link href={link}>
          <div style={{display: 'flex', marginTop: 2}}><Twitter /></div>
          <div>{linkTitle}</div>
        </Link>
      </div>
    </Inner>
  );
}

export const TeamWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Section>
      <SectionEdgePattern />
      <Wrapper>
        {children}
      </Wrapper>
    </Section>
  );
}

export default TeamTile;
