import { FC } from "react";

export const NewWindow: FC<{ color?: string; size?: string; }> = ({ color, size="21px" }) => {
return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 14 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2"
        d="M10.815 9.23v3.61a2 2 0 01-2 2H3a2 2 0 01-2-2V5.621a2 2 0 012-2h2.908M8.01 1.453H13v4.974"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2"
        d="M12.874 1.607l-.613.613-3.834 3.834-3.834 3.834"
      ></path>
    </svg>
);
}

export default NewWindow;