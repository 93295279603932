import { FC } from 'react';
import styled from '@emotion/styled';
import SeshLogo from '../assets/logo.svg';
import Opensea from '../assets/opensea';
import Gif from '../assets/prereveal.gif';
import CoverOne from '../assets/banner-one.jpg';
import Pattern from '../assets/pattern.svg';
import NewWindow from '../assets/new-window';
import TwitterCircle from '../assets/twitter-circle';
import Discord from '../assets/discord';
import Aura from '../assets/aura';
import EdgeBottom from '../assets/edge-bottom.svg';

const Outer = styled.div`
  background: #ed7029;
  background-image: url(${Pattern});
  background-repeat: repeat;
  background-position: 50% 50%;
  background-size: 200px;
  background-attachment: fixed;
  z-index: 1;
`

const SectionEdgePatternBottom = styled.div`
  -webkit-mask-image: url(${EdgeBottom});
  mask-image: url(${EdgeBottom});
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  display: flex;
  width: calc(100% + 1px);
  height: 72px;
  background: orange;
  position: absolute;
  bottom: -55px;
  left: -1px;
  overflow: hidden;
  background-color: #ed7029;
  background-image: url(${Pattern});
  background-repeat: repeat, no-repeat;
  background-position: 50% 50%;
  background-size: 200px, cover;
  background-attachment: fixed;
  }
`

export const Section = styled.section`
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
`

export const Navigation = styled.nav`
  margin-top: 44px;
  padding: 24px 24px 0px 24px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-content: end;
  gap: 16px;
  background: transparent;
  background-attachment: fixed;
  @media (min-width: 600px) {
    margin-top: 48px;
    padding: 24px 24px;
  }
  @media (min-width: 880px) {
    margin-top: 0;
  }
`

export const Secondary = styled.a`
  cursor: pointer;
  text-decoration: none;
  background:  #EBB803;
  border-radius: 8px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  border: 4px solid #172018;
  padding: 12px 16px;
  line-height: 1;
  font-family: bungee, sans-serif;
  font-weight: 400;
  font-style: normal;
  display: none;
  &:hover {
    background:  #EBB803;
    color: #172018;
  }
  background:  #172018;
  color: white;
  &:hover {
    background:  #172018;
    color: white;
  }
  &:visited {
    background:  #172018;
    color: white;
  }
  &:active {
    background:  #172018;
    color: white;
  }
  @media (min-width: 1100px) {
    display: flex; 
    gap: 12px; 
    alignItems: center;
  }
`

export const Menu = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  align-items: start;
`

export const Logo = styled.div`
  display: flex;
  width: 240px;
  height: auto;
  & > img {
    filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, .24));
  }
`

export const ImageReel = styled.div`
  display: flex;
  margin-bottom: -140px;
  width: 280px;
  border-radius: 280px;
  overflow: hidden;
  border: 8px solid #172018;
  margin-top: 24px;
  z-index: 1;
`

export const IntroSection = styled.div`
  line-height: 1.15;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 48px 32px;
  margin: 48px 0;
  text-align: center;
  gap: 32px;
  @media (min-width: 1024px) {
    width: 600px;
    padding: 140px 48px 32px;
  }
`

export const IntroPrimary = styled.a`
  display: flex;
  align-items: center;
  gap: 24px;
  font-size: 24px;
  text-decoration: none;
  padding: 12px 36px;
  border-radius: 60px;
  background: linear-gradient(180deg, #ff8d4c 0%, #ed7029 100%);
  color: var(--primary);
  cursor: pointer;
  border-radius: 8px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 18px 32px;
  font-family: bungee, sans-serif;
  font-weight: 400;
  font-style: normal;
  justify-content: space-between;
  &:hover {
    color: var(--primary);
    background: linear-gradient(180deg, #ff8d4c 0%, #ed7029 100%);
  }
  &:visited {
    color: var(--primary);
    background: linear-gradient(180deg, #ff8d4c 0%, #ed7029 100%);
  }
  &:active {
    color: var(--primary);
    background: linear-gradient(180deg, #ff8d4c 0%, #ed7029 100%);
  }
`

const Tag = styled.span(() => ({
  fontSize: 14,
  letterSpacing: 1,
  lineHeight: 1,
  margin: 8,
  textTransform: 'uppercase',
  background: '#ffffff75',
  fontWeight: 900,
  padding: '4px 8px',
  borderRadius: 4,
  color: 'var(--primary)',
  fontFamily: 'helvetica',
}));

const MobileActions = styled.div(() => ({
  display: 'flex', 
  gap: 12, 
  alignItems: 'center',
  justifyContent: 'center',
  '@media (min-width: 900px)': {
    display: 'none',
  },
}));

const Mosaic = styled(Section)(() => ({
  backgroundImage: `url("${CoverOne}")`, 
  backgroundPosition: '50% 50%', 
  backgroundSize: 'contain', 
  backgroundAttachment: 'fixed',
  display: 'flex', 
  justifyContent: 'end',
  '@media (min-width: 880px)': {
    backgroundSize: '120%',
  },
  '@media (min-width: 1200px)': {
    backgroundSize: 'cover', 
  }
}));


const Heading = styled.h2(() => ({
  margin: 0, 
  fontSize: 36,
  fontFamily: 'helvetica',
  '@media (min-width: 761px)': {
    marginBottom: 24,
    fontSize: 48,
  }
}));

const Desktop = styled.a(() => ({
  display: 'none', 
  textDecoration: 'none',
  '@media (min-width: 880px)': {
    display: 'flex'
  }
}));

const MintButtonText = styled.span(() => ({
  fontSize: 21,
  '@media (min-width: 761px)': {
    fontSize: 28,
  }
}));

export const Intro: FC<{  }> = () => {
  return (
    <>
    <Outer>

      <Navigation>
        <div />
        <div>
          <Logo>
            <img width="280" src={SeshLogo} alt="SeshStreet banner" style={{width: '100%', height: 'auto'}}/>
          </Logo>
          <p style={{ fontSize: 24, textAlign: 'center', margin: 0, marginTop: 10, fontWeight: 800 }}>nostalgia & good vibes</p>
        </div>
        <Menu>
          <div style={{display: 'flex', gap: 24, alignItems: 'center'}}>
            <div style={{display: 'flex', gap: 12, alignItems: 'center'}}>
              <Desktop href="https://twitter.com/seshstreet" target="_blank" rel="noreferrer">
                <TwitterCircle />
              </Desktop>
              <Desktop href="https://discord.gg/tdy3Yv5C" target="_blank" rel="noreferrer">
                <Discord />
              </Desktop>
              <Desktop href="https://opensea.io/collection/seshstreet" target="_blank" rel="noreferrer">
                <Opensea />
              </Desktop>
              <Desktop href="https://hub.auraexchange.org/collection/ethereum/0x2574e937bb143d8e71cc0ed582b2faf2938e7e16" target="_blank" rel="noreferrer">
                <Aura />
              </Desktop>
              
            </div>
            <Secondary 
              href="https://app.niftykit.com/drops/sesh-street"
              target="_blank"
            >
                <span style={{marginTop: 2}}>Mint now</span>
                <NewWindow color='white' size='16' />
            </Secondary>
          </div>
        </Menu>
      </Navigation>

      <Section>
        <ImageReel>
          <img width="240" src={Gif} alt="SeshStreet banner" style={{width: '100%', height: 'auto'}}/>
        </ImageReel>
        <SectionEdgePatternBottom />
      </Section>

  </Outer>

  <Mosaic>          
    <IntroSection>
      <IntroPrimary 
        href="https://app.niftykit.com/drops/sesh-street"
        target="_blank">
          <MintButtonText>Mint Now</MintButtonText>
          <NewWindow color='var(--primary)' />
      </IntroPrimary>
      <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 4}}>
        <Tag>Items / <b style={{ fontSize: 15, letterSpacing: 2 }}>6969</b></Tag>
        <Heading>Generative digital collectibles</Heading>
      </div>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        {/* <p>We use <a href="https://niftykit.com/" style={{color: 'var(--yellow)'}}>Niftykit.com</a> as our Mint Partner</p> */}
        <MobileActions>
          <a href="https://twitter.com/seshstreet" target="_blank" rel="noreferrer">
            <TwitterCircle color='white' />
          </a>
          <a href="https://discord.gg/tdy3Yv5C" target="_blank" rel="noreferrer">
            <Discord color='white' />
          </a>
          <a href="https://opensea.io/collection/seshstreet" target="_blank" rel="noreferrer">
            <Opensea color='white' />
          </a>
          <a href="https://hub.auraexchange.org/collection/ethereum/0x2574e937bb143d8e71cc0ed582b2faf2938e7e16" target="_blank" rel="noreferrer">
            <Aura color='white' />
          </a>

        </MobileActions>
      </div>
    </IntroSection>
  </Mosaic>
  </>
  );
}

export default Intro;
