import { FC } from 'react';
import styled from '@emotion/styled';
import Opensea from '../assets/opensea';
import Gif from '../assets/shades-of-frog.gif';
import CoverOne from '../assets/shades-of-frog.png';
import NewWindow from '../assets/new-window';
import Aura from '../assets/aura';

const Section = styled.section`
background: transparent;
display: flex;
justify-content: center;
align-items: center;
position: relative;
flex-direction: column;
`

const ImageReel = styled.div`
display: flex;
width: 280px;
border-radius: 280px;
overflow: hidden;
border: 8px solid #172018;
margin-bottom: 24px;
margin-top: 24px;
`

const IntroSection = styled.div`
line-height: 1.15;
color: white;
display: flex;
flex-direction: column;
align-items: center;
padding: 120px 48px 32px;
text-align: center;
gap: 32px;
@media (min-width: 1024px) {
  width: 700px;
  padding: 140px 48px 32px;
}
`

const IntroPrimary = styled.a`
display: flex;
align-items: center;
gap: 24px;
font-size: 24px;
text-decoration: none;
padding: 12px 36px;
border-radius: 60px;
background: linear-gradient(180deg, #e8ca42 0%, #b9a23a 100%);
color: var(--primary);
cursor: pointer;
border-radius: 8px;
text-transform: uppercase;
font-weight: bold;
padding: 18px 32px;
font-family: bungee, sans-serif;
font-weight: 400;
font-style: normal;
justify-content: space-between;
&:hover {
  color: var(--primary);
  background: linear-gradient(180deg, #e8ca42 0%, #b9a23a 100%);
}
&:visited {
  color: var(--primary);
  background: linear-gradient(180deg, #e8ca42 0%, #b9a23a 100%);
}
&:active {
  color: var(--primary);
  background: linear-gradient(180deg, #e8ca42 0%, #b9a23a 100%);
}
`

const Tag = styled.span(() => ({
  fontSize: 14,
  letterSpacing: 1,
  lineHeight: 1,
  margin: 8,
  textTransform: 'uppercase',
  background: '#ffffff75',
  fontWeight: 900,
  padding: '4px 8px',
  borderRadius: 4,
  color: 'var(--primary)',
  fontFamily: 'helvetica',
}));

const Mosaic = styled(Section)(() => ({
  backgroundImage: `url("${CoverOne}")`, 
  backgroundPosition: '50% 50%', 
  backgroundSize: 'contain', 
  backgroundAttachment: 'fixed',
  zIndex: 1,
  display: 'flex', 
  justifyContent: 'end',
  '@media (min-width: 880px)': {
    backgroundSize: '120%',
  },
  '@media (min-width: 1200px)': {
    backgroundSize: 'cover', 
  }
}));


const Heading = styled.h2(() => ({
  margin: 0, 
  fontSize: 36,
  fontFamily: 'helvetica',
  '@media (min-width: 761px)': {
    marginBottom: 24,
    fontSize: 48,
  }
}));

const MintButtonText = styled.span(() => ({
  fontSize: 21,
  '@media (min-width: 761px)': {
    fontSize: 28,
  }
}));

export const SecretMint: FC<{  }> = () => {
  return (
    <Mosaic>        
      <IntroSection>
        <ImageReel>
          <img width="240" src={Gif} alt="SeshStreet banner" style={{width: '100%', height: 'auto'}}/>
        </ImageReel>  
        <IntroPrimary 
          href="https://app.niftykit.com/collections/shades-of-frog"
          target="_blank">
            <MintButtonText>Secret Mint</MintButtonText>
            <NewWindow color='var(--primary)' />
        </IntroPrimary>
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 4}}>
          <Tag>Items / <b style={{ fontSize: 15, letterSpacing: 2 }}>8888</b></Tag>
          <Heading>Shades of Frog • 0.0025Ξ</Heading>
          <h4 style={{fontSize: 21, textAlign: 'center', margin: 0, marginTop: 0, marginBottom: 12, maxWidth: '140ch'}}><i></i>I created this collection a few months before SeshStreet, as I'm a big fan of Pixel-Art – Rollablazer</h4>
          <h4 style={{fontSize: 21, textAlign: 'center', margin: 0, marginTop: 0, marginBottom: 12, maxWidth: '140ch'}}>Feel free to mint one if you like the art.</h4>
        </div>
        <div style={{display: 'flex', gap: 12}}>
          <a href="https://hub.auraexchange.org/collection/ethereum/0x69e0e33011e8624d1fd488bfd3dd3885a5d02a3c" target="_blank" rel="noreferrer">
            <Aura color='white' />
          </a>
          <a href="https://opensea.io/collection/shades-of-frog" target="_blank" rel="noreferrer">
            <Opensea color='white' />
          </a>
        </div>
      </IntroSection>
    </Mosaic>
  );
}

export default SecretMint;
