import styled from '@emotion/styled';

export const Container = styled.div`
  background: #ed7029;
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Navigation = styled.nav`
  padding: 24px 24px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-content: end;
  gap: 16px;
  background: #ed7029;
`

export const Menu = styled.div`
  display: flex;
  gap: 16px;
  justify-content: end;
  align-items: start;
`

export const Intro = styled.div`
  width: 400px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 140px 48px 32px;
  margin: 48px 0;
  text-align: center;
  gap: 32px;
`

export const Section = styled.section`
  background: #ed7029;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
`

export const ImageReel = styled.div`
  display: flex;
  margin-bottom: -140px;
  width: 280px;
  border-radius: 280px;
  overflow: hidden;
  border: 8px solid #172018;
  margin-top: 24px;
`


export const Logo = styled.div`
  display: flex;
  width: 240px;
  height: auto;
  margin-top: 8px;
  & > img {
    filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, .24));
  }
`

export const Button = styled.button`
cursor: pointer;
background:  #EBB803;
border-radius: 8px;
text-transform: uppercase;
font-size: 16px;
font-weight: bold;
border: 4px solid #172018;
padding: 12px 16px;
line-height: 1;
font-family: bungee, sans-serif;
font-weight: 400;
font-style: normal;
&:hover {
  background:  #EBB803;
  color: #172018;
}
&:visited {
  background:  #EBB803;
  color: #172018;
}
&:active {
  background:  #EBB803;
  color: #172018;
}
`

export const Primary = styled(Button)`
  background:  #ed7029;
  color: #172018;
  &:hover {
    background:  #ed7029;
    color: #172018;
  }
  &:visited {
    background:  #ed7029;
    color: #172018;
  }
  &:active {
    background:  #ed7029;
    color: #172018;
  }
`

export const Secondary = styled.a`
  cursor: pointer;
  text-decoration: none;
  background:  #EBB803;
  border-radius: 8px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  border: 4px solid #172018;
  padding: 12px 16px;
  line-height: 1;
  font-family: bungee, sans-serif;
  font-weight: 400;
  font-style: normal;
  &:hover {
    background:  #EBB803;
    color: #172018;
  }
  background:  #172018;
  color: white;
  &:hover {
    background:  #172018;
    color: white;
  }
  &:visited {
    background:  #172018;
    color: white;
  }
  &:active {
    background:  #172018;
    color: white;
  }
`

export const IntroPrimary = styled.a`
  font-size: 24px;
  text-decoration: none;
  padding: 12px 36px;
  border-radius: 60px;
  background:  #EBB803;
  color: #172018;
  cursor: pointer;
  background:  #EBB803;
  border-radius: 8px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 18px 32px;
  font-family: bungee, sans-serif;
  font-weight: 400;
  font-style: normal;
  &:hover {
    background:  #EBB803;
    color: #172018;
  }
  &:visited {
    background:  #EBB803;
    color: #172018;
  }
  &:active {
    background:  #EBB803;
    color: #172018;
  }
`

export const Inner = styled.div`
  background: #EBB803;
  color: white;
  flex: 1;
  display: flex;
  padding-top: 90px;
  flex-direction: column;
  margin-top: -60px;
  align-items: center;
  padding-bottom: calc(156px + env(safe-area-inset-bottom));
  gap: 32px;
  justify-content: start;
  @media (min-height: 660px) {
    justify-content: center;
    padding-top: 0;
    padding-bottom: calc(16px + env(safe-area-inset-bottom));
  }
  @media (max-height: 800px) {
    padding-top: 0px;
    margin-top: 68px;
  }
`

export const PFP = styled.img`
  width: 190px;
  height: auto;
  border-radius: 190px;
  box-shadow: 0 12px 121px 0 #b6e8cc;
  transition: box-shadow 0.3s ease-in-out;
  @media (min-height: 600px) {
    width: 250px;
    height: auto;
    border-radius: 250px;
  }
`


export const Profile = styled.div`
  width: 240px;
  height: 240px;
  border-radius: 240px;
  border: 8px solid #172018;
  overflow: hidden;
`

export const OpenSea = styled.a`
  cursor: pointer;
  display: flex;
  &:hover img { 
    transition: box-shadow 0.3s ease-in-out;
    box-shadow: 0 12px 121px 0 #89d7ab;
  }
`

export const SafetyFirstInner = styled.img`
    width: 100%;
    height: auto;
    display: 'block';
`

export const Link = styled.a`
  cursor: pointer;
  display: flex;
  width: 48px;
  height: 48px;
  @media (min-width: 500px) {
    width: 52px;
    height: 52px;
  }
  @media (max-height: 800px) {
    width: 38px;
    height: 38px;
  }
`

export const Skull = styled.img`
  width: 100%;
  height: auto;
`
export const Dr = styled.img`
width: 100%;
height: auto;
`

export const Portfolio = styled.div`
  display: flex;
  gap: 24px;
  @media (max-height: 800px) {
    gap: 12px;
    padding-left: 8px;
  }
`

export const Footer = styled.footer`
  background: black;
  height: 100vh;
  width: 100%;
  bottom: 0;
  padding: 24px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  flex-direction: column;
  color: #EBB803;
  gap: 8px;

`

export const FooterText = styled.p`
  display: flex;
  flex-direction: column;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 10px;
  text-align: center;
  margin: 0;
  @media (min-width: 500px) {
    display: block;
    font-size: 11px;
  }
`

export const FooterInfo = styled.span`
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  @media (min-width: 500px) {
    font-size: inherit;
  }
`

export const Hidden = styled.span`
  display: none;
  @media (min-width: 500px) {
    display: inline-block;
  }
`


export const Tunes = styled.div`
  padding: 24px;
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr auto;
  line-height: 14px;
  position: absolute;
  justify-content: space-between;
`



export const PlayPause = styled(Button)`
  height: 40px;
  background: #ed7029;
  width: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  justify-content: center;
  border: none;
  background: rgba(0, 0, 0, .05);
  &:hover {
    background: rgba(0, 0, 0, .09);
  }
  &:visited {
    background: rgba(0, 0, 0, .09);
  }
  &:active {
    background: rgba(0, 0, 0, .09);
  }
`

export const Artist = styled.a`
  display: block;
  font-size: 14px;
  font-weight: 600;
  width: fit-content;
  text-decoration: none;
  line-height: 1;
  box-shadow: inset 0 -1px 0 0 #172018;
  margin: 0;
  color: #172018;
  padding: 2px 0;
  margin-bottom: 2px;
  font-family: bungee, sans-serif;
  @media (min-width: 500px) {
    padding: 2px 0;
  }
  @media (min-width: 600px) {
      padding: 2px 0;
  }
  :hover {
    padding: 2px 0;
  }
`

export const Song = styled.p`
  font-variantNumeric: 'proportional-nums', 
  fontFamily: helvetica;
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  color: #172018;
  `

export const TunePFP = styled.img`
  width: 42px;
  height: 42px;
  border-radius: 100%;
  @media (min-width: 600px) {
    width: 48px;
    height: 48px;
    border-radius: 100%;
}
`

export const SongBlock = styled.div`
display: flex;
align-items: center;
gap: 8px;
`
