import { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import Tilt from "react-parallax-tilt";
import Pattern from '../assets/pattern.svg';
// https://www.geeksforgeeks.org/tilt-effect-in-reactjs/

const Card = styled(Tilt)(({ background }: {background?: string; }) => ({
  width: 'auto',
  height: 'auto',
  borderRadius: 'calc(3mm / 1.5)',
  backgroundColor: background,
  backgroundImage: `url(${Pattern})`,
  backgroundRepeat: 'repeat, no-repeat',
  backgroundPosition: '50% 50%',
  backgroundSize: '200px, cover',
  overflow: 'hidden',
  boxShadow: '0 6px 8px 0 rgba(0,0,0,0.1), 1px 8px 12px 0 rgba(0,0,0,0.1), -1px 8px 12px 0 rgba(0,0,0,0.1)',
  '@media (min-width: 900px)' :{
    borderRadius: '3mm',
  }
}));

const Image = styled.div(({ background }: {background?: string;}) => ({
  display: 'flex',
  width: 'calc(6.3cm / 1.5)',
  height: 'calc(8.8cm / 1.5)',
  '&:after': {
    content: `''`,
    display: 'block',
    position: 'absolute',
    boxShadow: 'inset 0 -1px 0 0 rgba(255,255,255,0.2)',
    width: '100%',
    height: '100%',
    zIndex: -1
  },
  '&:before': {
    content: `''`,
    display: 'block',
    position: 'absolute',
    left: '0',
    top: '0',
    borderRadius: '3mm',
    width: 'calc(100%)',
    height: 'calc(100%)',
  },
  '@media (min-width: 550px)' :{
    width: 'calc(6.3cm / 1.5)',
    height: 'calc(8.8cm / 1.5)',
  },
  '@media (min-width: 900px)' :{
    width: '6.3cm',
    height: '8.8cm',
  }

}));

const InnerImage = styled.img(({ background }: {background?: string;}) => ({
  width: '100%',
  height: 'auto',
  display: 'block',
}));

const Badge = styled.div(({ background = "white" }: {background?: string;}) => ({
  width: 'calc(1cm / 1.5)',
  height: 'calc(1cm / 1.5)',
  borderRadius: '1cm',
  display: 'block',
  background: 'linear-gradient(-72deg, #dedeff, #ffffff 16%, #dedeff 21%, #ffffff 24%, #555564 27%, #dedeff 36%, #ffffff 45%, #ffffff 60%, #dedeff 72%, #ffffff 80%, #dedeff 84%, #555564)',
  boxShadow: '0 1px 0 0 rgba(0,0,0,0.4)',
  position: 'absolute',
  bottom: 'calc(0.3cm / 1.5)',
  right: 'calc(0.3cm / 1.5)',
  zIndex: 1,
  '@media (min-width: 900px)' :{
    width: '1cm',
    height: '1cm',
    bottom: '0.3cm',
    right: '0.3cm',
  },
}));

const Content = styled.div(({ background }: {background?: string;}) => ({
  position: 'absolute', 
  width: '100%', 
  height: '100%',
  display: 'flex', 
  justifyContent: 'center',
  alignItems: 'start',
  padding: 16
}));

const Name = styled.h2(({ background }: {background?: string;}) => ({

  margin: 0,
  color: 'black',
  fontFamily: 'bungee, sans-serif',
  fontWeight: 400,
  fontStyle: 'normal',
  padding: '8px 10px',
  background: background,
  borderRadius: 8,
  lineHeight: 1,
  fontSize: 'calc(10px / 1.5)',
  letterSpacing: 'calc(8px / 1.9)',
  boxShadow: 'inset 0 -1px 0 0 rgba(255,255,255,0.2), inset 0 1px 0 0 rgba(0,0,0,0.2)',
  '@media (min-width: 900px)' :{
    fontSize: 10,
    letterSpacing: 8,
  },
}));


const TiltComponent: FC<{ style?: any, title?: string; children?: ReactNode, glare: string, background?: string, image: string }> = ({ style, background, title = "Name", children, image, glare }) => {
  return (
    <div style={style}>
    <Card 
      glareEnable={true} 
      tiltMaxAngleX={10} 
      tiltMaxAngleY={10} 
      perspective={1000} 
      glareColor={glare}
      background={background}
    >
      {children}
      <Image>
        <Content>
          <Name background={background}>{title}</Name>
          <Badge />
        </Content>
        <InnerImage src={image} alt="Rollablazer bubblegum card" />
      </Image>
    </Card>
    </div>
  );
}

  
export default TiltComponent;