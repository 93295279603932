import { useState, useEffect, useRef } from "react";
import styled from '@emotion/styled';
import TuneBoxControls from "./TuneBoxControls";
import { Artist, Song, SongBlock, TunePFP } from "../styles";
import Pattern from '../assets/pattern.svg';


export const TuneBoxStyles = styled.div`
  background: #ed7029;
  background-image: url(${Pattern});
  background-repeat: repeat;
  background-position: 50% 50%;
  background-size: 200px;
  background-attachment: fixed;
  padding: 12px;
  line-height: 14px;
  box-sizing: border-box;
  width: 100%;
  z-index: 3;
  position: fixed;
  @media (min-width: 880px) {
    background: transparent;
    position: absolute;
    border-radius: 0 0 17px;
    width: auto;
    padding: 16px 24px;
  }
`

export const TuneBoxInnerStyles = styled.div`
  position: relative; 
  display: flex; 
  gap: 24px; 
  align-items: center;
  justify-content: space-between;
  @media (min-width: 880px) {
    justify-content: start;
    }
`

  // @ts-ignore
  export const TuneBox = ({ tracks }) => {
    // State
    const [trackIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
  
    // Destructure for conciseness
    const { title, artist, time, artistLink, image, audioSrc } = tracks[trackIndex];
  
    // Refs
    const audioRef = useRef(new Audio(audioSrc));
    const intervalRef = useRef();
    const isReady = useRef(false);
  
    const startTimer = () => {
      // Clear any timers already running
      clearInterval(intervalRef.current);
      // @ts-ignore
      intervalRef.current = setInterval(() => {
        // @ts-ignore
      }, [1000]);
    };

    useEffect(() => {
      if (isPlaying) {
        audioRef.current.play();
        startTimer();
      } else {
        audioRef.current.pause();
      }
    }, [isPlaying]);
  
    // Handles cleanup and setup when changing tracks
    useEffect(() => {
      audioRef.current.pause();
  
      audioRef.current = new Audio(audioSrc);
  
      if (isReady.current) {
        audioRef.current.play();
        setIsPlaying(true);
        startTimer();
      } else {
        // Set the isReady ref as true for the next pass
        isReady.current = true;
      }
    }, [audioSrc, trackIndex]);
  
    useEffect(() => {
      // Pause and clean up on unmount
      return () => {
        audioRef.current.pause();
        clearInterval(intervalRef.current);
      };
    }, []);
  
    return (
      <TuneBoxStyles>
        <TuneBoxInnerStyles>
          <SongBlock className="track-info">
            <TunePFP
              src={image}
              alt={`track artwork for ${title} by ${artist}`}
            />
            <div style={{display: 'grid', gap: 4}}>
              <Artist target="_blank" href={artistLink} rel="noreferrer" className="artist">{artist}</Artist>
              <Song className="title">{title} – {time}</Song>
            </div>
          </SongBlock>
          <TuneBoxControls
            isPlaying={isPlaying}
            onPlayPauseClick={setIsPlaying}
          />
                        
        </TuneBoxInnerStyles>
      </TuneBoxStyles>
    );
  };


export default TuneBoxControls;
