import { FC } from "react";

export const Discord: FC<{ color?: string; size?: string; }> = ({ color = '#172018', size="40px" }) => {
return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 40 40"
    >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM23.3789 11C25.0889 11.2901 26.7535 11.8037 28.3303 12.5276C31.0467 16.5102 32.3964 21.0021 31.898 26.1849C30.0626 27.5457 28.0071 28.5792 25.8216 29.24C25.3295 28.5778 24.8944 27.8749 24.521 27.1389C25.2323 26.8723 25.9189 26.5436 26.5729 26.1565C26.403 26.041 26.235 25.9123 26.0708 25.7779C24.1716 26.6735 22.0987 27.1379 20 27.1379C17.9013 27.1379 15.8284 26.6735 13.9292 25.7779C13.7669 25.9028 13.5988 26.0315 13.4271 26.1565C14.0798 26.5429 14.7651 26.871 15.4752 27.137C15.1014 27.8734 14.6663 28.5769 14.1746 29.24C11.991 28.5765 9.937 27.5426 8.10192 26.183C7.67719 21.7138 8.52665 17.1803 11.6602 12.5314C13.2386 11.8073 14.9043 11.2926 16.6154 11C16.8493 11.4195 17.061 11.851 17.2496 12.2929C19.071 12.0176 20.9233 12.0176 22.7447 12.2929C22.9332 11.851 23.1449 11.4194 23.3789 11ZM13.8518 21.0324C13.8518 22.3574 14.8296 23.4345 16.0132 23.4345C17.2194 23.4345 18.1519 22.3574 18.1727 21.0324C18.1935 19.7073 17.2232 18.6208 16.0094 18.6208C14.7956 18.6208 13.8518 19.7073 13.8518 21.0324ZM21.8273 21.0324C21.8273 22.3574 22.8013 23.4345 23.9868 23.4345C25.193 23.4345 26.1236 22.3574 26.1444 21.0324C26.1652 19.7073 25.2024 18.6208 23.9868 18.6208C22.7711 18.6208 21.8273 19.7073 21.8273 21.0324Z"
            fill={color} 
        />
    </svg>
);
}

export default Discord;







