import { FC } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react'


const Pulse = keyframes`
  0% { opacity: 30%; }
  45% { opacity: 100%; }
  65% { opacity: 100%; }
  100% { opacity: 30%; }
`

const Load = keyframes`
  0% { transform: translate3d(0, 100px, 0); }
  100% { transform: translate3d(0px, 0px, 0); }
`

export const El = styled.div(() => ({
  animation: `${Pulse} 1.6s ease-in-out infinite, ${Load} 1s ease-in-out`,
  filter: 'drop-shadow(0px 1px 1px rgba(0,0,0,1))',
  mixBlendMode: 'overlay',
  position: 'fixed',
  bottom: 24,
  left: 'calc(50% - (38px / 2))',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  alignItems: 'center',
  pointerEvents: 'none'
}));
  
export const Scroll: FC<{ }> = () => {
  return (
    <El>
      <div style={{display: 'flex'}}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="40"
          fill="none"
          viewBox="0 0 24 40"
          >
          <rect width="1" height="6" x="11" y="8" fill="#ffffff" rx="0.5"></rect>
          <rect width="22" height="38" x="0.5" y="0.5" stroke="#ffffff" rx="11"></rect>
        </svg>
      </div>
      <span style={{ color: 'white' }}>Scroll</span>
    </El>
  );
}

export default Scroll;
    