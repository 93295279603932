import { FC } from "react";


const TwitterCircle: FC<{ color?: string; size?: string; }> = ({ color = '#172018', size="40px" }) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 40 40"
    >
      <g clipPath="url(#clip0_152_2495)">
        <path
          fill={color}
          fillRule="evenodd"
          d="M40 20c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0s20 8.954 20 20zm-10.239-3.096c0-.19 0-.38-.013-.568A9.042 9.042 0 0032 14.058a9.163 9.163 0 01-2.592.692 4.436 4.436 0 001.984-2.432 9.201 9.201 0 01-2.866 1.068c-1.709-1.77-4.568-1.856-6.386-.191a4.329 4.329 0 00-1.306 4.201c-3.63-.176-7.01-1.847-9.302-4.594-1.198 2.01-.586 4.58 1.397 5.872a4.575 4.575 0 01-2.049-.55v.055c0 2.094 1.515 3.896 3.621 4.31a4.614 4.614 0 01-2.038.076c.592 1.792 2.286 3.018 4.217 3.054A9.217 9.217 0 0110 27.442a13.025 13.025 0 006.919 1.971v.004c8.302 0 12.842-6.702 12.842-12.513z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_152_2495">
          <path fill="#fff" d="M0 0H40V40H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default TwitterCircle;
