import { FC } from "react";

export const Aura: FC<{ color?: string; size?: string; }> = ({ color = '#172018', size="40px" }) => {
return (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 40 40"
    >
      <path fill={color}
        d="M20,0C9,0,0,9,0,20s9,20,20,20s20-9,20-20S31,0,20,0z M26.9,28.9l-6.1-15.5c-0.2-0.4-0.4-0.5-0.8-0.5
        c-0.4,0-0.6,0.1-0.8,0.5l-6.1,15.5H9.3l6.5-17.2c0.4-1.3,1.9-2.6,4.2-2.6H20h0.1c2.2,0,3.7,1.3,4.2,2.6l6.5,17.2H26.9z"/>
    </svg>

</>
);
}

export default Aura;