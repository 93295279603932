
import { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import Pattern from '../assets/pattern.svg';
import Edge from '../assets/edge.svg';
import EdgeBottom from '../assets/edge-bottom.svg';

const Profile = styled.div`
  width: 240px;
  height: 240px;
  border-radius: 240px;
  box-shadow: 0 4px 0 0 #00000030;
  overflow: hidden;
  border: 4px solid #05050d;
`

const Section = styled.section`
  z-index: 2;
  box-sizing: border-box;
  background: #ed7029;
  background-image: url(${Pattern});
  background-repeat: repeat;
  background-position: 50% 50%;
  background-size: 200px;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  padding: 48px 48px 96px 48px;
  display: flex;
  justifyContent: center;
  gap: 48; 
  padding-bottom: 120px;
  @media (min-width: 900px) {
    padding: 96px 48px;
  }
`

const SectionEdgePattern = styled.div`
  -webkit-mask-image: url(${Edge});
  mask-image: url(${Edge});
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  display: flex;
  width: 100%;
  height: 56px;
  background: orange;
  position: absolute;
  top: -55px;
  background-color: #ed7029;
  background-image: url(${Pattern});
  background-repeat: repeat, no-repeat;
  background-position: 50% 50%;
  background-size: 200px, cover;
  background-attachment: fixed;
  }
`

const SectionEdgePatternBottom = styled.div`
  -webkit-mask-image: url(${EdgeBottom});
  mask-image: url(${EdgeBottom});
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  display: flex;
  width: calc(100% + 1px);
  height: 72px;
  background: orange;
  position: absolute;
  bottom: -55px;
  left: -1px;
  overflow: hidden;
  background-color: #ed7029;
  background-image: url(${Pattern});
  background-repeat: repeat, no-repeat;
  background-position: 50% 50%;
  background-size: 200px, cover;
  background-attachment: fixed;
  }
`

const Wrapper = styled.div(() => ({
  display: 'grid',
  gridTemplateColumns: 'auto',
  gridTemplateRows: 'auto auto',
  gap: 96,
  '@media (min-width: 700px)': {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    alignItems: 'start',
    gap: 48,
    gridTemplateRows: 'auto auto',
  },
  '@media (min-width: 1200px)': {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    gridTemplateRows: 'auto auto',
    alignItems: 'start',
    justifyContent: 'center'
  },
}));

const Title = styled.h1`
  margin: 0;
  line-height: 1;
  font-size: 32px;
  font-family: bungee, sans-serif;
  font-weight: 400;
  font-style: normal;
  @media (min-width: 1024px) {
    font-size: 54px;
  }
`

const Heading = styled.h3(() => ({
  margin: 0, fontSize: 18, fontFamily: 'bungee, sans-serif', fontWeight: 400, fontStyle: 'normal'
}));

export const RoadmapTile: FC<{ img: string, description?: string, type: string, title: string, complete: boolean }> = ({ img, title, description, type, complete }) => {
  return (
    <div style={{textAlign: 'center', width: 224, display: 'grid', gap: 24, justifyContent: 'center' }}>
      <Profile style={{width: 140, height: 140, margin: '0 auto'}}>
        {complete === true && <img width="60" src={img} alt={title} style={{width: '100%', height: 'auto'}} />}
        {complete === false && <img width="60" src={img} alt={title} style={{width: '100%', height: 'auto', filter: 'grayscale(1)'}} />}
      </Profile>
      <div>
      <Heading>{title}</Heading>
      <div style={{display: 'flex', gap: 8, alignItems: 'center', justifyContent: 'center'}}>
        {complete === true && <div style={{width: 18, height: 18, background: '#00cd00', borderRadius: '100%', border: '2px solid #3eea3e', boxShadow: '0 2px 2px 0 #af460b'}}></div>}
        {complete === false && <div style={{width: 18, height: 18, background: 'orange', borderRadius: '100%', border: '2px solid #ffbd44', boxShadow: '0 2px 2px 0 #af460b'}}></div>}
        <p style={{margin: 0, marginTop: 4, fontSize: 18, fontWeight: 800, opacity: 0.62, textTransform: 'uppercase'}}>{type}</p>
      </div>
      </div>
    </div>
  );
}

export const RoadmapBox: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Section>
      <SectionEdgePattern />      
      <Title>No roadmap</Title>
      <div style={{display: 'grid', gridTemplateColumns: '1fr', gap: 24, alignItems: 'center', marginBottom: 36}}>
        <h4 style={{fontSize: 21, textAlign: 'center', margin: 0, marginTop: 24, marginBottom: 12, maxWidth: '68ch'}}>SeshStreet is actively looking for ways to provide value as we scale. Have feedback on the ideas? Provide feedback in our User feedback channel on <a style={{color: 'black'}} href="https://discord.gg/nRzKfFFM" target="_blank" rel="noreferrer">Discord</a>.</h4>
      </div>
      <Wrapper>
        {children}
      </Wrapper>
      <SectionEdgePatternBottom />
    </Section>
  );
}


export default RoadmapTile;
