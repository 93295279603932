import { FC } from 'react';
import styled from '@emotion/styled';
import Pattern from '../assets/pattern.svg';
import Edge from '../assets/edge.svg';
import TiltComponent from './BubbleGumCard';
import Bubblegum1 from '../assets/bubblegum/card-1.png';
import Bubblegum2 from '../assets/bubblegum/card-2.png';
import Bubblegum3 from '../assets/bubblegum/card-3.png';
import gum from '../assets/bubblegum.png';
import EdgeBottom from '../assets/edge-bottom.svg';

const Section = styled.section`
  display: grid;
  background-color: #ed7029;
  background-image: url(${Pattern});
  background-repeat: repeat, no-repeat;
  background-position: 50% 50%;
  background-size: 200px, cover;
  background-attachment: fixed;
  z-index: 2;
  position: relative;
  padding: 60px 0 120px 0;
  box-sizing: border-box;
  @media (min-width: 600px) {
    padding: 60px 48px 148px 48px;
  }
  @media (min-width: 1024px) {
    padding: 120px 90px 200px 90px;
  }
`

const SectionEdgePattern = styled.div`
  -webkit-mask-image: url(${Edge});
  mask-image: url(${Edge});
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  display: flex;
  width: 100%;
  height: 56px;
  background: orange;
  position: absolute;
  top: -55px;
  background-color: #ed7029;
  background-image: url(${Pattern});
  background-repeat: repeat, no-repeat;
  background-position: 50% 50%;
  background-size: 200px, cover;
  background-attachment: fixed;
  }
`

const SectionEdgePatternBottom = styled.div`
  -webkit-mask-image: url(${EdgeBottom});
  mask-image: url(${EdgeBottom});
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  display: flex;
  width: calc(100% + 1px);
  height: 72px;
  background: orange;
  position: absolute;
  bottom: -55px;
  left: -1px;
  overflow: hidden;
  background-color: #ed7029;
  background-image: url(${Pattern});
  background-repeat: repeat, no-repeat;
  background-position: 50% 50%;
  background-size: 200px, cover;
  background-attachment: fixed;
  }
`

const Wrap = styled.div`
  display: flex;
  flex-direction: column; 
  gap: 72px; 
  max-width: 100%; 
  text-align: center; 
  justify-content: start;
  align-items: center;
  margin-top: 0px;
  @media (min-width: 600px) {
    gap: 96px;
    margin-top: 60px;
  }
`

// const WrapInner = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center; 
//   gap: 24px;
//   max-width: 24ch;
//   @media (min-width: 600px) {
//     max-width: initial;
//   }
// `

const Inner = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  align-items: center;
`

const GumWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 1;
  bottom: -34px;
  left: 0;
  @media (min-width: 500px) {
    bottom: -80px;
  }
  @media (min-width: 600px) {
    bottom: -100px;
  }
  @media (min-width: 1024px) {
    bottom: -100px;
  }
`


const Gum = styled.img`
  display: block;
  height: auto;
  width: 300px;
  @media (min-width: 400px) {
    width: 380px;
  }
  @media (min-width: 500px) {
    width: 480px;
  }
  @media (min-width: 550px) {
    width: 540px;
  }
  @media (min-width: 1024px) {
    width: 600px;
  }
`



// const Tag = styled.span`
//   font-size: 14px;
//   letter-spacing: 1px;
//   line-height: 1;
//   margin: 8px;
//   text-transform: uppercase;
//   background: #ffffff75;
//   font-weight: 900;
//   padding: 4px 8px;
//   border-radius: 4px;
//   color: var(--primary);
//   font-family: helvetica;
// }
// `

// const Title = styled.h1`
//   margin: 0;
//   line-height: 1;
//   font-size: 32px;
//   font-family: bungee, sans-serif;
//   font-weight: 400;
//   font-style: normal;
//   @media (min-width: 1024px) {
//     font-size: 54px;
//   }
// `

const Toggle = styled.div(() => ({
  display: 'none',
  '@media (min-width: 500px)' : {
    display: 'block',
  },
}));

export const BubbleGumCards: FC = () => {

  return (    
    <Section>
      <SectionEdgePattern />
      <Wrap>
        {/* <WrapInner>
          <Tag>Idea only</Tag>
          <Title>Bubble gum cards?</Title>
        </WrapInner> */}
        <Inner>
          <Toggle>
            <TiltComponent image={Bubblegum1} glare="#ffe073"background="#ebb803" title="Rollablazer" />
          </Toggle>
          <TiltComponent image={Bubblegum3} glare="#b94bd8"background="#b94bd8" title="West Toast" style={{zIndex: 1, transform: 'scale3d(1.3, 1.3, 1.3)'}} />
          <Toggle>
            <TiltComponent image={Bubblegum2} glare="#36b4b1"background="#36b4b1" title="Bad Download" />
          </Toggle>
        </Inner>
      </Wrap>
      <GumWrapper>
        <Gum src={gum} alt="Gum" />
      </GumWrapper>
      <SectionEdgePatternBottom />
    </Section>
  );
}

export default BubbleGumCards;
