import { FC } from 'react';
import styled from '@emotion/styled';
import Pattern from '../assets/3D-Character.jpeg';
import Rolla from '../assets/sesh-in-game.gif';

const Title = styled.h1`
  margin: 0;
  line-height: 1;
  font-size: 28px;
  font-family: bungee, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #ed7029;
  @media (min-width: 1024px) {
    font-size: 54px;
  }
`

const SubHeading = styled.p`
  margin: 0;
  font-weight: 400;
  font-style: normal;
  max-width: 52ch;
  font-size: 16px;
  text-align: center;
  margin: 0px 0px 12px;
  max-width: 68ch;
  font-weight: 800;
  color: white;
  text-shadow: 0 4px 8px rgba(0 0 0 / 0.2);
  @media (min-width: 1024px) {
    font-size: 21px;
  }
`

const Section = styled.section`
  z-index: 0;
  background: black;
  background-image: url(${Pattern});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-attachment: scroll;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  // Additional
  background-color: #dd3a58;
  alignItems: center;
  padding: 48px 48px;
  @media (min-width: 1024px) {
    height: 100vh;
    min-height: 800px;
    padding: 24px 90px 0px 90px;
    background-attachment: fixed;
  }
`


const Link = styled.a`
  color: #ff8a47;
  &:hover {
    color: #ff8a47;
  }
  &:visited {
    color: #ff8a47;
  }
  &:active {
    color: #ff8a47;
  }
`

const Wrap = styled.div`
  display: flex;
  flex-direction: column; 
  gap: 24px; 
  align-content: center; 
  max-width: 600px;
  text-align: center; 
  justify-content: center;
  align-items: center;
  padding: 24px 0; 
`

const Tag = styled.span`
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1;
  margin: 8px;
  text-transform: uppercase;
  background: #ffffff75;
  font-weight: 900;
  padding: 4px 8px;
  border-radius: 4px;
  color: var(--primary);
  font-family: helvetica;
}
`

export const Profile = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 24px 24px 0 rgba(0,0,0,0.35);
  margin-bottom: 24px;
  border: 4px solid rgb(221 80 249 / 62%);
  @media (min-width: 1024px) {
    width: 250px;
    height: 250px;
  }
`

export const Rig: FC = () => {

  return (    
    <Section>
      <Wrap>


          <Profile>
            <img width="450" src={Rolla} alt="Rollablazer" style={{width: '100%', height: 'auto'}} />
          </Profile>

          <Tag>Completed</Tag>
          <Title>3D Rigged Character</Title>
          <SubHeading>Try <Link href="https://twitter.com/VineClimbGame" target="_blank" rel="noreferrer">Vine Climb</Link> today! Created by <Link href="https://twitter.com/NexusVReality" target="_blank" rel="noreferrer">Nexus Corp</Link></SubHeading>



      </Wrap> 
    </Section>
  );
}

export default Rig;
